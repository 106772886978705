@import '~antd/dist/antd.less';

// Override antd
@ant-border: 1px solid var(--border-color);

.bg-dark-green,
.c-sidebar,
.c-sidebar-footer {
	background-color: var(--primary-color) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	background-color: var(--primary-color);
	color: #fff;
}
.ant-btn-primary:not([disabled]) {
	background-color: var(--primary-color);
	border-color: var(--primary-color);
	&:hover,
	&:active,
	&:focus {
		background-color: var(--primary-color);
		border-color: var(--primary-color);
	}
}

.ant-table-tbody > tr.ant-table-row-selected > td {
	background-color: #d4dee3;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-time-panel-column
	> li.ant-picker-time-panel-cell-selected
	.ant-picker-time-panel-cell-inner {
	background: var(--primary-color);
	border-color: var(--primary-color);
	color: #fff;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
	border-color: var(--primary-color);
}

.ant-link {
	color: var(--primary-color);
}

.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
	background-color: var(--primary-color);
	border-color: var(--primary-color);
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
	background-color: var(--primary-color);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: var(--primary-color);
}

.c-sidebar-nav-item {
	.c-sidebar-nav-link {
		position: relative; // For left border
	}
}

#side-nav-items-wrapper > li > :first-child {
	font-weight: bold;
	text-transform: uppercase;
}

.light-color {
	color: var(--secondary-color) !important;
}

.c-sidebar-nav-link.c-active {
	background-color: var(--secondary-color) !important;
	&::before {
		content: '';
		width: 4px;
		height: 100%;
		position: absolute;
		background-color: #fff;
		// border: 3px solid #fff;
		top: 0;
		left: 0;
	}
}

.ant-tabs-ink-bar {
	background: var(--primary-color) !important;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
	background: #ebf2f3 !important;
}
.ant-card-body {
	padding: 12px;
}

.ant-message {
	.anticon {
		vertical-align: text-top;
	}
}
.edit-icon-color {
	color: var(--primary-color);
}
.clone-icon-color {
	color: #00bcd4;
}
.ant-input-prefix {
	margin-right: 9px;
}
.ant-input-clear-icon:last-child {
	font-size: 18px;
}
.ant-form-item {
	margin-bottom: 12px;
}
.ant-form-item-explain {
	font-size: 10px !important;
}

.ant-form-vertical .ant-form-item-label,
.ant-col.ant-form-item-label {
	padding-bottom: 4px !important;
}

.ant-form-item-label {
	padding-bottom: 4px !important;
}

.ant-form-item-label > label {
	height: max-content !important;
}

.ant-form-item-explain,
.ant-form-item-extra {
	min-height: 15px;
}

.ant-form-item-explain,
.ant-form-item-extra {
	line-height: 1.1;
	font-size: 10px;
	padding-top: 2px;
	min-height: 12px !important;
}

.ant-btn {
	.anticon {
		vertical-align: text-top;
	}
}

.ant-dropdown-trigger > .anticon.anticon-down {
	vertical-align: middle;
}

.ant-btn.ant-btn-default {
	&:hover,
	&:focus,
	&:active {
		color: var(--primary-color);
		border-color: var(--primary-color);
	}
}
.ant-pagination {
	li {
		margin-bottom: 5px;
	}
}
.ant-pagination-item-active {
	border-color: var(--primary-color) !important;
}
.ant-pagination-item {
	&:hover a,
	&:focus a,
	&:active a {
		color: var(--primary-color) !important;
	}
}
.ant-pagination-item-active a {
	color: var(--primary-color) !important;
	font-weight: 600;
}

.app-box-shadow {
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
		0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
}

.app-color-box-shadow {
	box-shadow: 8px 0px 12px 8px rgba(23, 107, 116, 0.12), 0 6px 16px 0 rgba(23, 107, 116, 0.08),
		0 9px 28px 8px rgba(23, 107, 116, 0.05);
}

.ant-pagination-item-link-icon,
.ant-pagination-item-link {
	color: var(--primary-color) !important;
	&:focus {
		font-weight: bolder;
	}
}

button.ant-pagination-item-link {
	display: flex !important;
	justify-content: center;
	align-items: center;
}

li.ant-pagination-item {
	&:hover {
		border-color: var(--primary-color) !important;
	}
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
	border-color: var(--primary-color) !important;
	color: var(--primary-color) !important;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
	border-color: var(--border-color) !important;
	color: var(--border-color) !important;
}

.anticon.ant-pagination-item-link-icon {
	vertical-align: middle !important;
}

span.ant-select-arrow {
	top: 40%;
	right: 14px;
}

.ant-select-arrow .anticon:not(.ant-select-suffix) {
	pointer-events: none !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
	margin-right: 18px;
	border-right: 1px solid var(--border-color);
}

.ant-table-cell {
	cursor: pointer;
}
.ant-table-cell.ant-table-selection-column,
.ant-table-cell.ant-table-cell-fix-right-first {
	cursor: auto !important;
}
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
	vertical-align: top;
}

.ant-modal-mask {
	z-index: 9995;
}
.ant-image-preview-mask,
.tox-tinymce-aux {
	z-index: 9997 !important;
}

.ant-modal-wrap {
	z-index: 9996;
}
.ant-image-preview-wrap {
	z-index: 9998 !important;
}

.ant-notification,
.ant-message {
	z-index: 9999;
}

.ant-select-item.ant-select-item-group {
	font-weight: bold;
	color: #100f0f;
}

.emma-lookup-select-input {
	min-width: 200px;
}

#emma-guest-view-details-content .ant-col {
	padding-left: 12px;
	padding-right: 12px;
}

.emma-lookup-select-input .ant-select-selection-placeholder {
	border-right: none !important;
}

.ant-popover-inner-content {
	max-width: 500px;
	max-height: 350px;
	overflow-y: scroll;
	white-space: pre-wrap;
}

// .ant-table-cell-fix-right.ant-table-cell-fix-right-first {
// 	right: 0 !important;
// }
// .ant-table-cell-fix-right.ant-table-cell-scrollbar {
// 	display: none !important;
// }
// .ant-table-body {
// 	overflow-y: auto !important;
// }

// antd override using custom class

.hide-tree-select-node-switcher-icon {
	.ant-select-tree-switcher.ant-select-tree-switcher-noop {
		display: none;
	}
}
.app-text-input.ant-input-affix-wrapper {
	padding: 0;
	.ant-input-prefix {
		padding: 5px 7px;
		margin-right: 0;
	}
	input.ant-input {
		padding: 4px 2px 4px 0;
		// border-right: @ant-border;
		border-left: none;
		border-radius: 0;
	}
	.ant-input-suffix {
		margin-left: 0;
		padding: 5px;
	}
}

.react-resizable {
	position: relative;
	background-clip: padding-box;
}

.react-resizable-handle {
	position: absolute;
	width: 10px;
	height: 100%;
	bottom: 0;
	right: -5px;
	cursor: col-resize;
	z-index: 1;
}

.ant-form-item-label.no-colon > label::after {
	display: none;
}

.ant-picker.app-date-input {
	border-left: none;
	padding: 0;
	border-radius: 0;
	&:hover,
	&.ant-picker-focused {
		border: @ant-border !important;
		border-left: none !important;
		outline: none !important;
		box-shadow: none !important;
	}
	.ant-picker-input {
		input {
			padding: 4px 2px 4px 2px;
			border-left: none;
			border-right: none;
		}
		.ant-picker-suffix {
			margin-left: 0;
			padding: 5px;
		}
	}
	&:not(.ant-picker-range) .ant-picker-clear {
		margin-right: 5px !important;
	}
	&.ant-picker-range .ant-picker-clear {
		right: 3px !important;
	}
	.ant-picker-active-bar {
		margin-left: 0 !important;
	}
}

.ant-select-selection-item-remove {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ant-select.app-select-input-with-prefix {
	.ant-select-selector {
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}
	.ant-select-clear {
		top: 44%;
		right: 32px;
		width: 20px;
		height: 20px;
	}
}

.ant-select.ant-select-single {
	.ant-select-clear {
		font-size: 18px;
		top: 10px;
		.anticon {
			background-color: #fff;
		}
	}
}

.seat-dragging {
	cursor: grab;
	background-color: #f1f5ff !important;
}

.antd-custom-file-input {
	overflow: hidden;
	padding-left: 4px;
}

::-webkit-file-upload-button {
	background: var(--primary-color);
	color: #fff;
	outline: none;
	border: none;
}

.ant-switch-checked {
	background-color: var(--secondary-color) !important;
}

.ant-modal-width-mid {
	width: 850px !important;
}
.ant-modal-width-full {
	width: calc(100vw - 5%) !important;
}

.status-dropdown-menu-item {
	.anticon {
		vertical-align: middle !important;
	}
}

.ant-modal-body {
	padding-left: 12px;
	padding-right: 12px;
	form {
		padding-left: 12px;
		padding-right: 12px;
	}
}

.ant-btn > .ant-btn-loading-icon .anticon {
	padding-right: 0px !important;
	margin-right: 8px !important;
}

.app-nav-btn {
	color: #fff !important;
	background-color: var(--primary-color);
	border-color: transparent !important;
}

.ant-btn-primary.light:not([disabled]),
.app-light-btn:not([disabled]) {
	background-color: var(--secondary-color);
	border-color: var(--secondary-color);
}

.ant-btn-primary.yellow,
.app-yellow-btn {
	background-color: var(--warning-color);
	border-color: var(--warning-color);
	color: #fff;
}

.yellow-text {
	color: var(--warning-color);
}

.ant-list-header {
	background-color: var(--body-bg-color) !important;
}

// ant table row dragging
.row-dragging {
	z-index: 9999999 !important; // View Pledge Modal
	max-width: 90vw;
}

.row-dragging:not(.not-tr) {
	background: #fafafa;
	border: 1px solid #ccc;
}

.row-dragging td {
	padding: 8px;
	visibility: hidden;
}

.row-dragging .drag-visible {
	visibility: visible;
}

/* TinyMCE editor override */
.tox-tinymce {
	border: 1px solid var(--border-color) !important;
	border-radius: 2px !important;
}

.tox .tox-button:not(.tox-button--naked, .tox-button--secondary) {
	background-color: var(--primary-color) !important;
	border-color: var(--primary-color) !important;
	border-radius: 4px !important;
}

// Others

.absolute-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* Left and right illustration */

// .bg-shapes-left {
// 	background-image: url(https://www.emma-live.com/assets/img/shapes/bg/bg-duotone-shapes-left.svg);
// }

// .bg-shapes-right {
// 	background-image: url(https://www.emma-live.com/assets/img/shapes/bg/bg-blue-shape-right.svg);
// }

// .bg-shapes.bg-shapes-bottom {
// 	background-position-y: bottom;
// 	top: initial;
// 	bottom: 20vh;
// }

// .bg-shapes.bg-shapes-right {
// 	left: initial;
// 	right: 0;
// 	background-position-x: right;
// 	background-size: 26%;
// }

// .bg-shapes.bg-shapes-top {
// 	background-position-y: top;
// 	top: 12vh;
// }
// .bg-shapes {
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	z-index: 1031;
// 	width: 10vw;
// 	height: 100vh;
// 	background-repeat: no-repeat;
// 	background-position-x: left;
// 	background-position-y: center;
// 	background-size: 44%;
// }

@primary-color: #38444f;