:root {
	/* Global Design Specific */
	--primary-color: #176b74;
	--secondary-color: #66b8b4;
	--body-bg-color: #ebedef;
	--warning-color: #f0bc42;
	--border-color: #d9d9d9;
	--main-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
		'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
		'Noto Color Emoji';
}
